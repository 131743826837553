.mobile-menu-items a:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../assets/icons/right-arrow-icon.svg");
}