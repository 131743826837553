.app-fold {
    border-radius: 300px 0px 0px 0px;
    background: #FFF;
    position: relative;
    z-index: 7;
    padding-top: 140px;
    margin-top: -110px;
    min-height: 100vh;
}

.app-fold .fold-title-section {
    padding: 0px 0px 60px;
    width: 100%;
}

.app-fold .fold-title-text {
    align-items: flex-start;
}

.app-fold .fold-title,
.app-fold .fold-paragraph {
    text-align: left;
}

.app-wrapper {
    position: relative;
    display: flex;
    padding-bottom: 100px;
    justify-content: center;
    align-items: center;
}

.app-info-card {
    position: absolute;
    display: flex;
    width: 254px;
    height: 54px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0px 8px 19px #0000002E;
    z-index: 5;
    background: #3327b6;
    background: linear-gradient(192deg, #7C3AED, #3327b6);
    border: 1px solid #f8f8f696;
}

.app-info-card.app-info1 {
    position: absolute;
    left: auto;
    top: 7.2%;
    right: 17%;
    bottom: auto;
}

.app-info-card.app-info2 {
    left: 8%;
    top: auto;
    right: auto;
    bottom: 30%;
}

.app-info-title {
    font-family: Raleway, sans-serif;
    font-size: 14px;
    line-height: 26px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 0px;
    color: #fff;
    padding-right: 0px;
}

.app-info-text {
    line-height: 26px;
    text-align: left;
}

.app-info-content {
    padding-right: 0;
    padding-left: 0px;
    position: relative;
    top: 0px;
}

.app-info-text {
    padding-right: 40px;
}

.app-mockup {
    position: relative;
}

.app-mockup img {
    width: 363px;
    height: 738px;
    position: relative;
    z-index: 2;
}

.app-video-container {
    position: absolute;
    width: 89.9%;
    left: 5%;
    top: 1.9%;
    border-radius: 6%;
    overflow: hidden;
}

.app-bg-video {
    width: 100%;
}

@media screen and (max-width: 1500px) {
    /* .app-fold {
        margin-top: -160px;
    } */
}

@media screen and (max-width: 1199px) {
    .app-fold .fold-paragraph {
        margin-top: 10px;
    }
}

@media screen and (max-width: 991px) {
    .app-fold {
        margin-top: 0px;
        padding-top: 100px;
    }
}

@media screen and (max-width: 767px) {
    .app-info-card.app-info1 {
        top: 3.2%;
        right: 50%;
        transform: translate(50%, 0);
        box-shadow: 0px 8px 23px 18px rgb(7 8 48);
    }

}

@media screen and (max-width: 479px) {
    .app-mock {
        width: 100%;
    }

    .app-mockup img {
        width: 100%;
    }
}

.store-link-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    padding: 60px 20px;
}

.store-link {
    width: 200px;
    cursor: initial !important;
    pointer-events: none;
}

.store-link .store-link-image {
    width: 100%;
}

@media screen and (max-width: 767px) {
    .store-link {
        width: 30vw;
    }

    .store-link-wrapper {
        /* display: grid;
        padding: 60px 20px 0px 20px; */
    }
}

@media screen and (max-width: 479px) {
    .store-link {
        width: 40vw;
    }
    .store-link-wrapper{
        display: block;
    }

    .store-link .store-link-image {
        width: auto;
        padding: 4px;
    }
}