.subscribe-fold {
    border-radius: 0px 0px 80px 0px;
    background: #4ADE80;
    height: 200px;
    padding: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-top: 73px;
    margin-bottom: 129px;
    box-sizing: border-box;
}

.subscribe-title {
    color: #1B1B1B;
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
}

.subscribe-text {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    color: #f9f9f9;
    font-family: Raleway, sans-serif;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
}

.subscribe-input {
    height: 52px;
    width: 45%;
    padding-left: 22px;
    padding-bottom: 3px;
    border: 1px none #f2f2f2;
    border-radius: 8px;
    background-color: #fff;
    color: #020202;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}

.subscribe-input {
    outline: none;
}

.mc-mail-input::placeholder {
    color: #71717A;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    text-transform: none;
}

.subscribe-button {
    height: 56px;
    width: 142px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: -60px;
    border: none;
    border-radius: 0px 8px 8px 0px;
    background-color: #f9f9f9;
    box-shadow: 1px 1px 3px 0 transparent;
    color: #1f2334;
    font-weight: 700;
    font-size: 15px;
    font-family: 'Raleway', sans-serif;
    position: relative;
    top: 1px;
}

.subscribe-fold .newsletter-input-container {
    float: initial;
}

.subscribe-fold .mc-mail-input {
    min-width: 300px;
}

.subscribe-fold .mc-button-wrap .mc-button-submit {
    background: #7C3AED;
    min-width: 188px !important;
    height: 48px;
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    text-transform: none;
}

.subscribe-fold .newsletter-input-fields {
    margin-right: 188px
}

.subscribe-fold .mc-message-container {
    color: #d6d7d7;
}

@media screen and (max-width: 991px) {
    .subscribe-fold {
        padding: 32px;
    }

    .subscribe-fold .mc-mail-input {
        min-width: initial;
    }

    .subscribe-title {
        font-size: 32px;
        line-height: 46px;
    }
}

@media screen and (max-width: 580px) {
    .hero-actions .newsletter-input-fields {
        width: 90%;
    }

    .mc-field-group {
        width: 100%;
    }
}

@media screen and (max-width: 479px) {

    .subscribe-title {
        font-size: 28px;
        line-height: 39px;
    }

    .subscribe-text {
        width: 100%;
        font-size: 16px;
        line-height: 23px;
    }

    .subscribe-input {
        width: 60%;
    }

    .subscribe-fold .mc-button-wrap .mc-button-submit {
        min-width: 70px !important;
    }

    .newsletter-input-container {
        width: 100%;
    }

    .newsletter-input-fields {
        width: 100%;
    }

    .hero-actions .newsletter-input-fields {
        width: 90%;
    }

    .mc-field-group {
        width: 100%;
    }

    .subscribe-fold .mc-mail-input {
        width: calc(100% - 67px);
        float: left;
    }

    .subscribe-fold .mc-submit-action-txt {
        display: none;
    }
}