.footer-wrapper {
    background: #262626;
    padding: 24px 120px;
    box-sizing: border-box;
}

.footer-wrapper .MuiContainer-root {
    max-width: 1200px;
    padding: 0 10px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footer-map-container {
    display: flex;
    gap: 20px;
}

.footer-map {
    width: 300px;
    height: 200px;
}

.footer-address {
    color: #FFF;
    font-family: Raleway;
    font-size: 14px;
    line-height: 1.4;
}

.footer-address h4 {
    margin: 0 0 10px;
    font-size: 16px;
    font-weight: 600;
}

.footer-address p {
    margin: 0;
}

.get-directions-link {
    display: inline-block;
    margin-top: 10px;
    color: #4CAF50;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease;
}

.get-directions-link:hover {
    color: #45a049;
    text-decoration: underline;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
}

.footer-copyright {
    color: #FFF;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.42px;
}

.footer-links {
    display: flex;
    gap: 32px;
}

.footer-legal-link {
    color: #FFF;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.42px;
    text-decoration-line: underline;
}

.footer-legal-link:hover {
    color: #fff;
}

@media screen and (max-width: 991px) {
    .footer {
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }

    .footer-wrapper {
        padding: 24px 32px;
    }

    .footer-map-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-address {
        text-align: center;
    }

    .footer-content {
        align-items: center;
        text-align: center;
    }

    .footer-links {
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }
}