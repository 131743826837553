.options-fold {
    padding: 50px 0;
}

.options-fold .MuiContainer-root {
    padding: 60px 10px 0;
}

.options-fold .fold-title {
    color: #101120;
    white-space: pre-line;
    margin-bottom: -40px;
}

.options-card {
    position: relative;
    min-height: 828px;
    display: flex;
    padding: 40px 81px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    flex: 1 0 0;
}

.card-essential-membership {
    border-radius: 0px 80px 0px 0px;
    background: #F5F5F5;
}

.card-elite-membership {
    border-radius: 80px 0px 0px 0px;
    background: #171717;
}

.card-essential-membership .options-card-title {
    color: #1B1B1B;
}

.card-elite-membership .options-card-title {
    color: #fff;
}

.options-card-title {
    text-align: center;
}

.options-card-title h3 {
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
    margin-bottom: 24px;
}

.options.card-title p {
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}

.options-card .card-img {
    width: 85%;
    margin-top: 10%;
    transform: translate(10.4%, 0);
}

.options-card-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-left: 40px;
}

.options-card-text p {
    position: relative;
    color: #1B1B1B;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}

.card-elite-membership p {
    color: #fff;
}

.options-card-text img {
    margin-right: 14px;
    position: absolute;
    top: 0;
    left: -40px;
    z-index: 3;
}

.options-card .nft-container {
    width: 90%;
    margin-bottom: -124px;
    margin-top: -90px;
}

.options-card .nft-frame {
    height: 540px;
}

.options-card-contribution {
    margin: 0;
    margin-bottom: 10px;
    padding-bottom: 6px;
    max-width: 530px;
    padding-left: 36px;
    padding-right: 36px;
    box-sizing: border-box;
    width: 100%;
    text-align: left !important;
    color: #fff;
    font-weight: 700;
}

.options-card-contribution p {
    margin: 0;
}

.options-card-contribution .price-info-rate {
    display: block;
    font-size: 11px;
    line-height: 0.2;
}

.options-card-contribution .price-info-sign {
    font-size: 9px;
    position: relative;
    top: -1px;
    left: -1px;
}

@media screen and (min-width: 678px) and (max-width: 900px) {

    .options-card .card-img {
        width: 65%;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1350px) {
    .options-card-text {
        /*margin-left: 0;*/
    }
}

@media screen and (max-width: 1215px){
    .options-card .nft-container{
        margin-top: -6vw;
        margin-bottom: -5.4vw;
    }
    .options-card .nft-frame {
        height: 36vw;
    }
}

@media screen and (max-width: 991px) {
    .options-fold {
        padding: 0;
    }

    .options-fold .fold-title-section {
        padding-bottom: 42px;
        padding-top: 70px;
    }

    .options-card-text {
        margin-left: 20px;
    }

    .options-card-text img {
        margin-right: 4px;
        width: 12px;
        top: 7px;
        left: -21px;
    }

    .options-card .card-img {
        width: 94%;
        transform: translate(5%, 0);
        max-width: 500px;
    }

    .options-fold .fold-title {
        font-size: 34px;
        line-height: 44px;
    }

    .options-card .nft-container {
        height: 41vw;
        margin-bottom: -10px;
        width: 100%;
    }

    .options-card-contribution {
        width: 100%;
        max-width: 530px;
    }

    .options-card button {
        position: static;
        width: 100%;
    }
    .options-card{
        padding: 40px 16vw;
        min-height: initial;
    }
    .options-card .nft-frame {
        height: 66vw;
        margin-top: -10vw;
    }

}

@media screen and (max-width: 767px) {
    .options-fold .fold-title {
        font-size: 30px;
        line-height: 38px;
    }

    .options-card {
        padding: 20px 40px;
    }

    .options-card .nft-container {

    }
}

@media screen and (max-width: 569px) {
    .options-card .nft-container {
        height: 57vw;
    }
    .options-card .nft-frame {
        height: 95vw;
        margin-top: -17vw;
    }
}

@media screen and (max-width: 479px) {
    .options-fold .fold-title {
        width: 340px;
        margin: 0 auto;
        margin-bottom: -40px;
    }

    .options-card-contribution {
        width: 100%;
    }

    .options-card-contribution .price-info-rate {
        width: 90px;
    }
}