.newsletter-input-container {
    display: inline-block;
    float: left;
    pointer-events: none !important;
}

.newsletter-input-fields {
    position: relative;
    display: inline-block;
    margin-right: 84px;
}

.mc-field-group {
    display: inline-block;
    float: left;
}

.mc-mail-input {
    height: 48px;
    min-width: 220px;
    outline: none !important;
    border-radius: 6px 0 0 6px;
    border: 1px solid #e2e3e2;
    padding: 0px 20px 0 30px;
    box-sizing: border-box;
    font-weight: 600 !important;
    font-family: 'Raleway', sans-serif !important;
    font-size: 14px;
}

.mc-mail-input:focus {
    border: 2px solid #7C3AED;
}

.mc-mail-input::placeholder {
    color: #b8b9b9;
    text-transform: uppercase;
}

.mc-mail-input::-webkit-input-placeholder {
    color: #b8b9b9;
    text-transform: uppercase;
}

.mc-mail-input::-ms-input-placeholder {
    color: #b8b9b9;
    text-transform: uppercase;
}


.mc-button-wrap {
    position: absolute;
    left: 98%;
}

.mc-button-wrap .mc-button-submit {
    border-radius: 0 6px 6px 0 !important;
    min-width: 70px !important;
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
    position: relative;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.mc-button-wrap .mc-button-submit:hover {
    background-color: #7C3AED;
}

.hero-cta-icon {
    width: 24px;
    position: absolute;
    top: 13.5px;
    right: 24px;
}

.mc-message-container {
    position: absolute;
    width: 50%;
    font-size: 13px;
    padding-left: 35px;
    text-align: left;
}

.mc-submit-action-txt {
    padding-right: 26px;
}

.loading-icon {
    transform: scale(0.6) !important;
    position: absolute;
    top: 18px;
    right: 30px;
}

.dot-windmill {
    position: relative;
    top: -10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    transform-origin: 5px 15px;
    animation: dot-windmill 2.8s infinite linear;
}

.dot-windmill::before,
.dot-windmill::after {
    content: "";
    display: inline-block;
    position: absolute;
}

.dot-windmill::before {
    left: -8.66254px;
    top: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
}

.dot-windmill::after {
    left: 8.66254px;
    top: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
}

@keyframes dot-windmill {
    0% {
        transform: rotateZ(0deg) translate3d(0, 0, 0);
    }

    100% {
        transform: rotateZ(720deg) translate3d(0, 0, 0);
    }
}

@media screen and (max-width: 580px) {
    .mc-button-wrap {
         left: initial !important;
        right: 0;
    }

    .mc-message-container {
        line-height: 20px;
        padding-left: 15px;
        width: 90%;
    }
}

@media screen and (max-width: 479px) {
    .mc-button-wrap {
        left: initial !important;
        right: 0;
    }

    .mc-message-container {
        line-height: 20px;
        padding-left: 15px;
        width: 90%;
    }
}