@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Raleway', sans-serif;
  overflow-x: hidden;
}

#root {
  overflow-x: hidden;
}

.page-register #root,
.page-es-register #root {
  height: 100vh;
  background-color: #4ADE80;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: 64px;
  line-height: 80px;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
}

h2 {
  font-size: 48px;
  line-height: 64px;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
}

h3 {
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
}

h4 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
}

h5 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
}

h6 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
}

p {
  line-height: 24px !important;
}

.subtitle-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
}

.subtitle-2 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
}

.body-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
}

.body-2 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
}

.caption {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
}

.overline {
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
}

.text-primary-main {
  color: #7C3AED;
}

.text-primary-light {
  color: #DEDAFE;
}

.text-dark-primary {
  color: #0A0B30;
}

.text-dark-secondary {
  color: #393B64;
}

.text-gray-primary {
  color: #374151;
}

.text-gray-secondary {
  color: #6B7280;
}

.text-white {
  color: #FFFFFF;
}

.text-link {
  color: #436EE5;
}

.button-large {
  font-size: 15px;
  line-height: 26px;
  font-weight: 600;
}

.button-medium {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}

.button-small {
  font-size: 13px;
  line-height: 22px;
  font-weight: 600;
}

/* Fold Text */
.fold-title-section {
  display: block;
  height: auto;
  margin-top: 0px;
  padding: 90px 0px 34px;
  flex-direction: column;
  align-items: center;
  grid-column-gap: 80px;
  grid-row-gap: 80px;
}

.lang-ro .fold-title-section {
  padding: 90px 0px 60px;
}

.fold-title-text {
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  text-align: center;
}

.fold-caption {
  color: #fff;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.fold-title {
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 4px;
  background-color: transparent;
  opacity: 1;
  font-family: Raleway, sans-serif;
  color: #101120;
  font-size: 73px;
  line-height: 96px;
  font-weight: 700;
}

.fold-subtitle {
  font-size: 36px;
  line-height: 44px;
}

@media screen and (max-width: 1920px) {
  .fold-title {
    font-size: 3.8vw;
    line-height: 5vw;
  }
}

.fold-paragraph {
  width: 100%;
  max-width: 700px;
  margin-bottom: 0px;
  color: #101120;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 0.2px;
  white-space: pre-wrap;
}

.fold-light .fold-title {
  color: #101120;
}

.fold-light .fold-paragraph {
  color: #2b2d42;
}

@media screen and (max-width: 991px) {
  .fold-title {
    font-size: 34px;
    line-height: 44px;
  }
}

.fold-caption {
  color: #737373;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.42px;
}

