/* ===== Hero Fold ===== */
.hero-fold {
    display: flex;
    height: 100vh;
    min-height: 700px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #4ADE80;
}

.hero-main-section {
    position: relative;
    /* padding: 16vh 0 16vh; */
    z-index: 2;
    top: -10vh;
}

.hero-content {
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: auto;
    flex: 0 auto;
    gap: 32px;
    text-align: left;
    padding-right: 20px;
    box-sizing: border-box;
}

.hero-title {
    color: #1B1B1B;
    font-family: Raleway;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.44px;
    width: 519px;
}

.hero-title-text {
    color: #1B1B1B;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.48px;
    width: 519px;
}

.hero-member-card {
    width: 690px;
    position: relative;
    left: -100px;
}

.hero-member-card img {
    width: 100%;
    display: block;
    position: relative;
    z-index: 10;
    height: 24vw;
    max-height: 420px;
}

.hero-member-card:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hero-member-card .nft-frame{
    max-height: 700px;
    height: 40vw;
    transform: translate(-50%, 0%) rotate(-5deg);
    right: -330px;
    top: 0;
    left: initial;
    pointer-events: none;
}

.hero-member-card .nft-container{
    position: relative;
    z-index: 10;
}

.hero-card {
    position: relative;
    z-index: 1;
    height: 390px;
    border-radius: 16px;
    background-color: #1b1f72;
    text-align: center;
}

.hero-card-overlay{
    width: 38vw;
    height: 38vw;
    max-height: 670px;
    max-width: 670px;
    position: absolute;
    top: 50%;
    left: 63%;
    transform: translate(-50%,-50%);
    background-color: #D1FAE5;
    /*background-image: url("../../../../assets/el-jardin-home-opt.jpeg");*/
    background-size: cover;
    z-index: 1;
    border-top-left-radius: 55%;
}

.hero-card-text {
    position: absolute;
    left: 20px;
    top: 20px;
    right: auto;
    bottom: auto;
    color: #fff;
}

.hero-card-label {
    font-size: 14px;
    text-align: left;
}

.hero-card-value {
    margin-bottom: 30px;
    font-family: Raleway, sans-serif;
    color: #f2f2f2;
    text-align: left;
}


.hero-card-image {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 160px;
    height: auto;
    border-radius: 16px;
}

.hero-card-id {
    position: absolute;
    left: 20px;
    top: auto;
    right: auto;
    bottom: 60px;
    font-family: Raleway, sans-serif;
    color: #393692;
    font-size: 28px;
    line-height: 39px;
    font-weight: 400;
}

.hero-actions {
    margin-top: 44px;
}

.hero-actions a .hero-cta-soon,
.hero-actions button .hero-cta-soon {
    display: none;
}

.hero-actions a,
.hero-actions button {
    height: 48px !important;
    border-radius: 0 !important;
    border-top-left-radius: 16px !important;
    padding: 0 30px !important;
    font-weight: 600 !important;
    font-family: 'Raleway', sans-serif !important;
    position: relative;
    margin-right: 20px;
    background: #7C3AED;
    color: #fff !important;
    letter-spacing: 0.6px;
    min-width: 180px;
}

.hero-actions a:hover {
    background: #7C3AED;
}

.hero-actions a.inactive,
.hero-actions button.inactive {
    border: 1px solid #e3e3e3;
    color: #b9b9b9 !important;
    background: #fff !important;
}

.hero-actions a.inactive .hero-cta-soon,
.hero-actions button.inactive .hero-cta-soon {
    display: block;
    position: absolute;
    background: #7C3AED;
    background: linear-gradient(192deg, rgba(84, 74, 211, 1) 0%, rgba(51, 39, 182, 1) 100%);
    color: #fff;
    font-size: 7px;
    font-weight: 700;
    border-radius: 3px;
    text-transform: uppercase;
    line-height: 14px;
    padding: 0 6px;
    top: -7px;
    right: 17px;
}

@media screen and (max-height: 800px) and (orientation: landscape) {
    .hero-main-section {
        padding: 25vh 0 16vh;
    }
}

/* Responsive hero */
@media screen and (max-width: 1500px) {
    .hero-member-card {
        width: 512px;
    }
    .hero-member-card .nft-frame{
        right: -240px;
    }
}

@media screen and (max-width: 1199px) {
    .hero-title {
        font-size: 32px;
        line-height: 46px;
        max-width: 94%;
    }

    .hero-member-card {
        left: -60px;
        top: 12px;
        width: 462px;
    }
}

@media screen and (max-width: 991px) {
    .hero-title {
        font-size: 34px;
        line-height: 44px;
    }

    .hero-fold {
        min-height: 100vh;
        height: auto;
        display: block;
        padding-bottom: 100px;
    }

    .hero-title-text {
        padding-right: 10px;
        width: auto;
    }

    .hero-member-card {
        left: 0;
        top: 0;
        max-width: 100%;
        margin-top: 40px;
    }
    .hero-card-overlay{
        width: 50vw;
        height: 50vw;
    }
    .hero-member-card .nft-frame{
        top: 13vw;
        height: 52vw;
    }
    .hero-main-section {
        padding: 24vh 0 16vh;
    }

    .hero-content {
        padding-right: 0;
    }

    .hero-actions {
        text-align: center;
    }

    .hero-actions button {
        margin: 0 0 20px 0;
    }

    .hero-actions .newsletter-input-fields {
        margin-right: 86px;
    }

    .hero-actions .newsletter-input-container {
        float: left;
        margin-bottom: 2px;
    }

    .hero-actions .mc-message-container {
        position: relative;
        line-height: 18px;
        padding-left: 0;
        text-align: center;
        width: 100%;
        margin-bottom: 10px;
    }

}

@media screen and (max-width: 580px) {
    .hero-fold .mc-mail-input {
        width: calc(100% - 67px);
        float: left;
    }

    .hero-actions {
        display: flex;
        flex-direction: column-reverse;
        width: 80%;
    }

    .hero-actions a,
    .hero-actions button {
        margin-bottom: 18px;
        margin-right: 0;
    }

    .hero-actions .mc-message-container {
        text-align: left;
        padding-left: 18px;
    }

    .hero-member-card img{
        height: auto;
        width: 70vw;
        margin: 0 auto;
        left: -5vw;
    }

    .hero-card-overlay{
        width: 70vw;
        height: 70vw;
        left: 58%;
    }

    .hero-member-card .nft-frame{
        right: -32vw;
        top: 5vw;
        height: 72vw;
    }
}

@media screen and (max-width: 479px) {
    .hero-fold .mc-mail-input {
        width: calc(100% - 67px);
        float: left;
    }

    .hero-actions {
        display: flex;
        flex-direction: column-reverse;
        width: 80%;
    }

    .hero-actions a,
    .hero-actions button {
        margin-bottom: 18px;
        margin-right: 0;
    }

    .hero-actions .mc-message-container {
        text-align: left;
        padding-left: 18px;
    }
}