body {
    overscroll-behavior: none;
}

.slider-wrapper {
    border-radius: 32px;
    overscroll-behavior: none;
    width: 100vw;
    /*height: 100vh;*/
    display: flex;
    flex-wrap: nowrap;
    padding: 0 10px;
    position: relative;
    z-index: 5;
}

.slide-container {
    position: absolute;
    text-align: center;
}

.slide {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
}

#slide3 {
    opacity: 1;
    visibility: visible;
}

.slide.first-slide {
    opacity: 1;
    visibility: visible;
}

.works-fold .fold-title-section {
    position: absolute;
    z-index: 2;
    left: 50vw;
    transform: translate3D(-50%, 0, 0);
    width: 100%;
    padding: 120px 0px 60px;
    opacity: 1;
}

.works-fold .fold-title-text {
    padding: 0;
}

.works-fold .card .card-bg-color {
    opacity: 0.5;
}

.works-fold .card .card-title {
    color: #101120;

}

.works-fold .card .card-text {
    color: #101120;
    font-weight: 400;
}

.works-fold .fold-title {
    color: #101120;
}

.slide-text {
    white-space: pre-line;
    color: #fff;
    display: inline-block;
    position: relative;
    z-index: 2;
    font-weight: 500;
}

.slide-title {
    color: #fff;
    font-weight: 600;
    line-height: 50px;
    font-size: 38px;
    position: relative;
    z-index: 2;
    margin-bottom: 10px;
    text-align: left;
}

.slide-image-element {
    height: 205px;
}

.slide-image {
    position: absolute;
    transform: translate3D(60%, -50%, 0);
}

.slide-title-no {
    font-size: 34px;
    font-weight: 600;
}

.slide .slide-container {
    transform: translate3D(-50%, -50%, 0);
    left: 50vw !important;
}

.slide-wrapper {
    position: relative;
}

.s-container-size-1 {
    width: 660px;
}

.s-container-size-2 {
    width: 920px;
}

.s-container-size-3 {
    width: 1040px;
}

.slide#slide1 .slide-title {
    width: 360px;
    display: inline-block;
}

.slide#slide1 .slide-text {
    width: 360px;
    text-align: left;
}

.slide#slide1 .slide-image {
    width: 36vmin;
    left: 140px;
    transform: translate3D(-60%, -50%, 0);
    top: 70%;
    max-width: 315px;
}

.slide#slide1 .slide-container {
    top: 74vh;
    left: 60vw;
    text-align: right;
}


.slide#slide3 .slide-title {
    width: 440px;
}

.slide#slide3 .slide-text {
    width: 410px;
}

.slide#slide3 .slide-image,
.slide#slide4 .slide-image,
.slide#slide5 .slide-image {
    width: 60vmin;
    max-width: 420px;
    right: 190px;
}

.slide#slide3 .slide-image {
    top: 51%;
}

.slide#slide4 .slide-image {
    top: 55.6%;
}

.slide#slide5 .slide-image {
    top: 53.6%;
}

.slide#slide3 .slide-container {
    text-align: left;
    top: 73.5vh;
    left: 37.3vw;
}


.slide#slide4 .slide-title {
    width: 440px;
}

.slide#slide4 .slide-text {
    width: 410px;
}

/*.slide#slide4 .slide-image {*/
/*    width: 35vmin;*/
/*    top: 50%;*/
/*    right: 200px;*/
/*    max-width: 420px;*/
/*}*/

.slide#slide4 .slide-container {
    text-align: left;
    top: 52.3vh;
    left: 39vw;
}

.slide#slide5 .slide-text {
    width: 390px;
}

.slide#slide5 .slide-title {
    width: 490px;
    margin-top: 0px;
    white-space: pre-wrap;
}

/*.slide#slide5 .slide-image {*/
/*    width: 50vmin;*/
/*    max-width: 420px;*/
/*    right: 160px;*/
/*    top: 60%;*/
/*}*/

.slide#slide5 .slide-container {
    text-align: left;
    top: 52.5vh;
    left: 36.8vw;
}

.slide#slide6 .slide-text {
    width: 450px;
}

.slide#slide6 .slide-title {
    width: 700px;
}

.slide#slide6 .slide-image {
    width: 730px;
    max-width: 540px;
    right: 230px;
    top: 94%;
    filter: brightness(0.86) contrast(1.1);
}

.slide#slide6 .slide-container {
    text-align: left;
    top: 52vh;
    left: 43.8vw;
}

.lang-es .slide#slide1 .slide-title {
    width: 370px;
}

.lang-es .slide#slide1 .slide-text {
    width: 370px;
}

.lang-es .slide#slide3 .slide-title {
    width: 500px;
}

.lang-es .slide#slide3 .slide-image {
    right: 160px;
}

.lang-es .slide#slide4 .slide-image {
    right: 120px;
}

.lang-es .slide#slide4 .slide-title {
    width: 550px;
}

.lang-es .slide#slide4 .slide-container {
    top: 53vh;
}

.lang-es .slide#slide5 .slide-image {
    right: 160px;
}

.lang-es .slide#slide5 .slide-title {
    width: 590px;
}

.lang-es .slide#slide5 .slide-container {
    top: 55.5vh;
}

.lang-es .slide#slide6 .slide-image {
    top: 88%;
}

.lang-es .slide#slide6 .slide-title {
    width: 680px;
}

.lang-es .slide#slide6 .slide-container {
    top: 53vh;
}

.works-fold-overlay {
    border-radius: 300px 0px 0px 0px;
    background: #404040;
    opacity: 1;
    width: 1180px;
    height: 100vh;
    position: absolute;
    border-radius: 50px;
    top: 270px;
    left: calc((100vw - 1180px)/2);
    transition: all 0s;
    min-height: 100vh;
}

.works-fold-overlay:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: rgb(151 147 154 / 37%);*/
    /* border-top-left-radius: 64px;
    border-top-right-radius: 64px; */
    border-radius: 300px 0px 0px 0px;
}

.network-fold:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 56%;
    /*background: linear-gradient(180deg, rgba(234,234,234,0) 0%, rgba(155,161,184,1) 70%);*/
}

.works-fold.works-mobile {
    background: #ffffff;
}

.works-fold.works-mobile .slider-wrapper {
    padding: 0;
    width: 100%;
    border-radius: 0;
}

.works-fold.works-mobile .fold-title-section {
    position: relative;
    left: initial;
    transform: none;
    padding: 120px 0px 30px;
}

.works-fold.works-mobile .fold-title {
    color: #101120;
}

.slider-howto-wrapper .slide-title-no {
    font-size: 18px !important;
    line-height: 32px !important;
}

.slider-howto-wrapper .card-title {
    font-size: 20px !important;
    line-height: 32px !important;
}

.slider-howto-wrapper .card {
    border-radius: 32px;
    height: 562px !important;
}

.slider-howto-wrapper .owl-item img {
    max-width: 90%;
    width: auto !important;
    height: auto !important;
    max-height: 260px;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0px;
}

.slider-howto-wrapper .card-content {
    left: 6%;
    width: 88%;
}

.slider-howto-wrapper .owl-stage .owl-item:nth-child(2) .card-bg-color,
.slider-howto-wrapper .owl-stage .owl-item:nth-child(4) .card-bg-color {
    background: linear-gradient(147deg, #232437 0%, #101120 100%);
}

.slider-howto-wrapper .owl-stage .owl-item:nth-child(1) img {
    max-height: 230px;
}

.slider-howto-wrapper .owl-stage .owl-item:nth-child(2) img {
    max-height: 200px;
    bottom: 10px;
}

.slider-howto-wrapper .owl-stage .owl-item:nth-child(3) img {
    max-height: 240px;
    bottom: -22px;
}

.works-mobile .slider-howto-wrapper .owl-stage .owl-item:nth-child(1) img {
    bottom: -150% !important;
    top: initial !important;
    right: 0;
    left: initial;
}

.works-mobile .slider-howto-wrapper .owl-stage .owl-item:nth-child(2) img {
    top: -150% !important;
    bottom: initial !important;
}

.works-mobile .slider-howto-wrapper .owl-stage .owl-item:nth-child(3) img {
    bottom: -100% !important;
    top: initial !important;
    right: 0;
    left: initial;
    ;
}


@media screen and (max-height: 850px) and (orientation: landscape) {
    .works-fold.works-fullscreen .fold-title-section {
        padding-top: 8vh;
        transition: all 0.15s ease-out;
    }
}

@media screen and (max-width: 1920px) {
    .slide-title {
        font-size: 2.1vw;
        line-height: 2.8vw;
    }

    .slide-title-no {
        font-size: 2vw;
    }
}

@media screen and (max-width: 1500px) {
    .slide#slide3 .slide-container {
        left: 42.3vw;
    }

    .slide#slide3 .slide-title {
        width: 410px;
    }

    .slide#slide5 .slide-title {
        width: 390px;
    }

    .lang-es .slide#slide3 .slide-title {
        width: 500px;
    }

    .lang-es .slide#slide4 .slide-title {
        width: 460px;
    }

    .lang-es .slide#slide6 .slide-title {
        width: 460px;
    }
}

@media screen and (max-width: 1199px) {
    .slide#slide4 .slide-container {
        top: 53.2vh;
    }

    .slide#slide5 .slide-container {
        top: 56.5vh;
    }

    .slide#slide5 .slide-title {
        width: 310px;
    }

    .lang-es .slide#slide4 .slide-title {
        width: 360px;
    }

    .lang-es .slide#slide6 .slide-title {
        width: 350px;
    }

    .lang-es .slide#slide5 .slide-title {
        width: 350px;
    }
}

@media screen and (max-width: 991px) {
    .works-fold-overlay {
        top: 530px;
    }

    .card-business-plan {
        height: 580px;
    }

    .slider-howto-wrapper .owl-item img {
        height: 200px !important;
    }
}

@media screen and (max-width: 767px) {
    .works-mobile .slider-howto-wrapper .owl-stage .owl-item img{
        transform: translate(-50%,140%);
        left: 50% !important;
        right: initial !important;
    }
    .works-mobile .slider-howto-wrapper .owl-stage .owl-item:nth-child(1) img{
        bottom: 0 !important;
    }
    .works-mobile .slider-howto-wrapper .owl-stage .owl-item:nth-child(2) img {
        top: initial !important;
        bottom: -30% !important;
    }
    .works-mobile .slider-howto-wrapper .owl-stage .owl-item:nth-child(3) img {
        bottom: 12% !important;
    }
    .works-mobile .slider-howto-wrapper .owl-stage .owl-item .item > div:first-child{
        min-height: calc(1200px - 105vw);
    }

}