#parallaxFolds {
    position: relative;
    z-index: 2;
}

#parallaxFolds .bg {
    /*background-image: url("../../../assets/benefits-how-fold-bg.webp"),url("../../../assets/benefits-how-fold-bg.jpg");*/
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 170%;
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: saturate(0.6);
    transform: translate3d(0, -21vh, 0);
}

#parallaxFolds .bg img {
    height: 100%;
}

#parallaxFolds .bg:after {
    z-index: 0;
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 240px;
    background: linear-gradient(-180deg, #fefefe, #fefefe);
}

/* ===== Fair Fold ===== */
.fair-fold {
    display: flex;
    /*height: 100vh;*/
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    margin-top: 1.4vw;
}

.fair-fold .fold-title {
    height: 60px;
}

.fair-fold .fold-paragraph {
    margin-top: 38px;
    margin-bottom: 7px;
}

.fair-wrapper {
    height: 760px;
    padding-right: 40px;
    padding-left: 40px;
    position: relative;
    margin: 0 10px;
}

.fair-fold .pin-spacer {
    top: 0 !important;
    left: 50% !important;
}

.fair-fold .fold-title-section {
    padding: 90px 0px 32px;
}

.lang-ro .fair-fold .fold-title-section {
    padding: 90px 0px 60px;
}

.hero-button-section {
    position: absolute;
    left: 0%;
    right: 0%;
    top: -130px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
}

.hero-button-container {
    width: 210px;
    height: 47px;
    border: 1px solid #ECFDF5;
    border-radius: 0 !important;
    border-top-left-radius: 16px !important;
    text-align: center;
    margin-bottom: 10px;
}

.hero-button {
    width: 210px;
    height: 48px;
    color: #f2f2f2;
    background-color: transparent;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    letter-spacing: 0.5px;
    font-size: 14px;
    border-radius: 0 !important;
    border-top-left-radius: 16px !important;
}

.hero-button:hover {
    background-color: transparent;
}

.hero-button-text {
    /* transition: all 0.37s cubic-bezier(.32, -.01, .15, .98); */
    display: block !important;
    line-height: 48px !important;
    border-radius: 19px !important;
    padding: 0 !important;
    font-weight: 600 !important;
    font-family: 'Raleway', sans-serif !important;
    color: #1B1B1B;
}

.hero-button:hover {
    box-shadow: 0px 4px 8px 0px rgb(16 17 32 / 6%);
}

.hero-button:hover .hero-button-text {
    cursor: pointer;
    transform: scale3d(1.05, 1.05, 1.05);
}

.fold-light .hero-button-arrow {
    filter: brightness(0);
}

.fold-light .hero-button:focus,
.fold-light .hero-button:active {
    box-shadow: none;
}

.fold-light .hero-button {
    color: #101120;
}

.fold-light .hero-button-container {
    border: 1px solid #1B1B1B;
    box-shadow: 0px 24px 48px 0px rgba(131, 135, 210, 0);
}

.fair-button-arrow {
    position: absolute;
    left: 0%;
    top: 130px;
    right: 0%;
    bottom: 20px;
    margin-right: auto;
    margin-left: auto;
    filter: brightness(0.9);
}

@media screen and (max-width: 991px) {}

/* ===== Benefits Fold ===== */
.benefits-fold {
    background: rgb(255, 255, 255);
    padding-bottom: 100px;
    margin-top: -60px;
    padding-top: 40px;
}

.benefits-fold .MuiContainer-root {
    padding: 0 10px;
}

.benefits-fold .fold-title-section {
    position: relative;
    z-index: 1;
    padding: 100px 0px 60px;
}

.benefits-grid {
    padding-top: 0;
}